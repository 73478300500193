import * as actionTypes from './action';

const initialState = {
    isLoading: false,
    error: undefined,
    auth: {}
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SIGNIN_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
        case actionTypes.AUTH_SIGNUP_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
        case actionTypes.AUTH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                auth: action.payload
            });
        case actionTypes.AUTH_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error                
            });
        case actionTypes.AUTH_SIGNOUT:
            return Object.assign({}, state, {
                isLoading: false,
               // auth: action.payload                
               auth: {}
            });
        default:
            return state;
    }
}

export default AuthReducer;