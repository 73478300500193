module.exports = {
	
	
	//api_base_path: 'https://api2-demo.joobster.at',
	//api_base_path: 'https://api2.joobster.at',
	api_base_path: '', 
	api_admin_path: '/admin', 
	 

	//api_base_path: '/admin',
	//	mainurl: 'https://api2-demo.joobster.at',
	//	mainurl: 'https://admin.joobster.at',
	//mainurl: '', 
	//pwd_reset_route: 'https://api2.joobster.at', 
	mandrillkey: 'SL0isrXw1DS6El50qzIGdw', 
	
	//mailchimpkey: 'f68d249fe2cc7c7128b82e8597ec69ed-us16'
	
}