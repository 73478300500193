import { fork } from 'redux-saga/effects';

// import all sagas
import { watchSignin, watchPWDReset } from '../views/Login/saga';
//import { watchGetClips, watchSyncClip } from '../views/Dashboard/saga';
//import { watchGetCategories } from '../views/Dashboard/sagaCategories';
//import { watchGetGroups } from '../views/Dashboard/sagaGroups';
//import { watchGetSuperGroups } from '../views/Dashboard/sagaSuperGroups';
//import { watchGetUser, watchAddUserToGroup, watchGetUserForGroup, watchGetAllGroup} from '../views/Administration/Gruppenverwaltung/saga'
//import { watchGetMicroCategories, watchSetMicroCategories } from '../views/Administration/Microlearningverwaltung/saga'
//import { watchGetSupergroupCategories, watchSetSupergroupCategories } from '../views/Administration/Kategorienmanagement/saga'



export default function* rootSaga() {
 yield [
    fork(watchSignin),
    fork(watchPWDReset),
 //   fork(watchGetClips),
 //   fork(watchSyncClip),
 //   fork(watchGetCategories),
 //   fork(watchGetGroups),
 //   fork(watchGetSuperGroups),
 //   fork(watchGetUser),
 //   fork(watchAddUserToGroup),
 //   fork(watchGetUserForGroup),
 //   fork(watchGetMicroCategories),
 //   fork(watchGetAllGroup),
 //   fork(watchSetMicroCategories),
 //   fork(watchGetSupergroupCategories),
 //   fork(watchSetSupergroupCategories)
 ];
}
