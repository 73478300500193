import React, { Component, Suspense} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { connect } from 'react-redux';



//const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));


class App extends Component {




  render() {
    return (

      <BrowserRouter>
        <div>
          <Route
            render={() =>
              this.props.auth.sessionId ? (

                <div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <DefaultLayout />
                  </Suspense>
                </div>

              ) :
                (
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Login />
                    </Suspense>
                  </div>
                )

            }

          />
        </div>

      </BrowserRouter>


    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
