import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import { createBrowserHistory} from 'history';
import { ConnectedRouter } from 'connected-react-router'

import authReducer from '../views/Login/reducer';
//import clipReducer from '../views/Dashboard/reducer';
//import categoryReducer from '../views/Dashboard/reducerCategories';
//import groupReducer from '../views/Dashboard/reducerGroups';
//import superGroupReducer from '../views/Dashboard/reducerSuperGroups';
//import userReducer from '../views/Administration/Gruppenverwaltung/reducer';
//import microCategoriesReducer from '../views/Administration/Microlearningverwaltung/reducer';
//import categoryReducerForSupergroup from '../views/Administration/Kategorienmanagement/reducer'

//ConnectedRouter(createBrowserHistory)

export default combineReducers({
    routing: routerReducer,
    authReducer
 //   clipReducer,
 //   categoryReducer,
 //   groupReducer,
 //   superGroupReducer,
 //   userReducer,
 //   microCategoriesReducer,
   // categoryReducerForSupergroup
})


